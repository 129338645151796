<template>
  <div id="data-list-list-view" class="data-list-container">

    <div id="ecommerce-wishlist-demo">
      <div class="items-grid-view vx-row match-height" appear>
        <template v-if="brands.length">
          <div class="vx-col lg:w-1/1 md:w-1/1 sm:w-1/1 w-full mb-5">
            <vs-button @click="$router.push({name:'brands-create'})" class="
                btn-add-new
                p-3
                mb-4
                mr-4
                rounded-lg
                cursor-pointer
                flex
                items-center
                justify-center
              ">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2">إضافة جديد</span>
            </vs-button>
          </div>

          <div class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full" v-for="item in brands" :key="item.id">
            <item-grid-view :item="item"  image_key="logo_path">
              <!-- SLOT: ACTION BUTTONS -->
              <template slot="action-buttons">
                <div class="flex flex-wrap">
                  <!-- PRIMARY BUTTON : REMOVE -->

                  <!-- SECONDARY BUTTON: MOVE TO CART -->

                  <div class="
                      item-view-danger-action-btn
                      p-3
                      flex flex-grow
                      items-center
                      justify-center
                      cursor-pointer
                    " @click="openConfirmArchive(item.id)">
                    <!-- <feather-icon icon="XIcon" svgClasses="h-4 w-4" /> -->

                    <span class="text-sm font-semibold ml-2"> ارشفة </span>
                  </div>
                  <div class="
                      item-view-secondary-action-btn
                      bg-primary
                      p-3
                      flex flex-grow
                      items-center
                      justify-center
                      text-white
                      cursor-pointer
                    " 
                    @click="editData(item)">
                    <span class="text-sm font-semibold ml-2"> تعديل</span>
                  </div>
                </div>
              </template>
            </item-grid-view>

          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import BrandAdd from "./BrandAdd.vue";
import moduleBrand from "@/store/brands/moduleBrand.js";
const ItemGridView = () => import("./components/ItemGridView.vue");

export default {
  components: {
    BrandAdd,
    ItemGridView,
  },
  data() {
    return {
      itemsPerPage: 5,
      isMounted: false,
      selected_id: 0,
      brand: {},
      increase_percentage: '',
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
    };
  },
  computed: {
    brands() {
      return this.$store.state.brand.brands;
    },
  },
  methods: {

    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    editData(data) {
      // console.log(data)
      this.$router.push({name: 'brands-edit',params: { id: data.id },})
    },
    getData() {
      this.$vs.loading();

      this.$store
        .dispatch("brand/fetchBrands")
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    openConfirmArchive(id) {
      this.selected_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد",
        text: ` هل انت متأكد من ارشفة هذه العلامة التجارية؟`,
        accept: this.archiveData,
        acceptText: "نعم",
        cancelText: "الغاء",
      });
    },
    archiveData() {
      this.$store
        .dispatch("brand/archiveBrand", this.selected_id)
        .then((response) => {
          this.successDialog(response.data.message);
        })
        .catch((error) => {
          this.errorDialog(error);
        });
    },
  },
  created() {
    if (!moduleBrand.isRegistered) {
      this.$store.registerModule("brand", moduleBrand);
      moduleBrand.isRegistered = true;
    }
    this.getData();
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>
